.container {
z-index: 10;
position: absolute;
width: 100%;
height: 100%;
top: 0;
background-color: #f1f1f1;
text-align: center;
font-family: Arial, Helvetica, sans-serif;
}
.iframe{
    width: 100%;
    height: 100%;
}
.mobile{
    width: 360px;
}
.wrapButtons{
gap: 10px;
z-index: 20;
position: absolute;
top: 0px;
left: 10px;
display: flex;
flex-direction: row-reverse;
}

.exit {
    min-width: 20px;
    cursor: pointer;
    background-color: red;
    padding: 2px 10px;
    border-radius: 0px 0px 5px 5px;
    color: white;
    border-color: transparent;
}
.pre {
    cursor: pointer;
    background-color: #9648e8;
    padding: 5px 10px;
    color: white;
    border-radius: 0px 0px 5px 5px;
    border-color: transparent;

}
