.topLeft {
    border-top: 2px solid #000;
}

.bottomLeft {
    border-top: 2px solid #000;
}

.topLeftHeader {
    font-weight: 700;
    padding: 7px 0;
    margin: 0;
}

.borderBot {
    border-bottom: 1px solid rgb(196, 194, 194);
    margin-bottom: 20px;
}

.previewImg {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.videoPreview {
    margin-bottom: 20px;
}
