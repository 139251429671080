
.container{
    margin-top: 30px;
    background-color: #fff;
    width: 100%;
    /* min-height: 350px; */
    padding: 30px 45px;
    box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    position: relative;
    transition: max-height 0.15s ease-out;
    overflow: hidden;

}

.folded {
    max-height: 90px;
    /* min-height: 0px; */
    overflow: hidden;
    transition: max-height 0.15s ease-in;
}