.wrapHeader {
    padding: 7px 0px;
    display: flex;
    gap: 5px;
    align-items: flex-end;
    /*border-bottom: 1px solid rgb(196, 194, 194);*/
    justify-content: space-between;
}

.header {
    font-weight: 700;
    border-bottom: 1px solid #ececec;
    line-height: 2;
    margin-bottom: 20px;
}

.container {
    margin-top: 30px;
    background-color: #fff;
    width: 100%;
    min-height: 350px;
    text-align: center;
    padding: 30px 45px;
    box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}

.wrapSide {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

.inputWrapper {
    display: flex;
    margin-top: 10px;
}

.input {
    max-width: 40px;
    margin-left: 10px;
    margin-right: 10px;
}