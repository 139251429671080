body{
    direction: rtl;
}
.container{
    margin: 0 auto;
    padding:50px;
    width: 1070px;
    border: 1px solid black;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #f1f1f1;
    margin-bottom: 50px;
}


