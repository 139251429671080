.container{
  display: flex;
  align-items: center;
}
.label{
  font-size: 22px;
  margin-right: 20px;
  font-weight: 700;
  margin-bottom: 2px;
}

.toggle {
  transition: background-color .3s;
  position: relative;
  width: 77px;
  height: 23px;
  border-radius: 12.6px;
  box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border: 1px solid #eee;
  padding-inline: .5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;

  &.on {
    background-color: #03d927;
  }

  &.off {
    background-color: #c50707;
  }

  &::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 6.9px;
    background-image: linear-gradient(to bottom, #c6c5c5, rgba(255, 253, 253, 0.78));
    position: absolute;
    z-index: 999999999;
    transform: translate(25%, 25%);
    transition: left .3s;
    top: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 38px;
    height: calc(100% - 2px);
    top: 50%;
    transform: translateY(-50%);
    border-radius: 12.6px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
    border-width: 1.3px;
    border-image-source: linear-gradient(to bottom, #cfcdcd, #eee);
    background-image: linear-gradient(to bottom, #fff 0%, #ccc), linear-gradient(to bottom, #cfcdcd, #eee);
    transition: left .3s;
  }

  &.on {
    &::before,
    &::after {
      left: calc(100% - 38px - 1px);
    }
  }

  &.off {
    &::before, &::after {
      left: 1px;
    }
  }

  span {
    color: #fff;
    font-weight: bold;
    font-size: 10px;
  }
}

