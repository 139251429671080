.container{
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 10px 20px;

}

h3{
    color: white;
}

.wrapLeft{
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 5px 0;
}

.button{
    border-radius: 4px !important;
    padding-inline: 10px;
    min-width: 70px;
    color: white;
    text-align: center;
    border: none;
}
.preview{
    background-color: rgb(0, 89, 255);
}

.save{
    background-color: green;
}

.cancel{
    background-color: red;
}

.disabled {
    background-color: #676767;
}

.statusMessage {
    font-size: 18px;
    color: antiquewhite;
    align-self: center;
}
