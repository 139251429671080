.input{
 border: 1px solid rgb(224, 224, 224);
 border-radius: 4px;   
 width: 100%;
 padding: 5px;
}


.input::placeholder {
 color: rgb(175, 172, 172);
 font-size: 15px;
 font-weight: 200;
}
