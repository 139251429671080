.container{
    background-color: #fff;
    width: 100%;
    min-height: 400px;
    padding: 30px 45px;
    box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}

.Detailcontainer {
    margin-top: 30px;
    display: flex;
    gap: 65px;

}
.right{
    flex:3;
    display: flex;
    flex-direction: column;
}
.flex1{
    flex:1
}
.topRight{
    display: flex;
    flex-direction: row;
    gap: 65px;
}

.left{
    flex:2;
}

.bottomRight{
    border-top: 2px solid #000;
    margin-top:30px;
    padding-top: 10px;

}
.topBottomRight{
    margin-top:20px;
}


