.container{
    background-color: #fff;
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
    box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}

.option{

}