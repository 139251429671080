.container{
    width: 100%;
    padding-bottom:15px;
    display: flex;
    align-items: center;
}
.noBM{
    padding-bottom:0px;

}

.label{
   margin-left: 10px; 
   white-space: pre;
}
