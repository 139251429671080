
.container{
    margin-top: 30px;
    background-color: #fff;
    width: 100%;
    min-height: 350px;
    padding: 30px 45px;
    box-shadow: 1px 1px 2px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);

}


.ul{
    background-color: #fff;
    width: 100%;
    height: 200px;
    display: flex;
    margin-top: 30px;
    justify-content: space-evenly;
    align-items: center;
    list-style-type: none;
}

.p{
    margin:10px 76px 0 0 ;
}
.floatingComment{
    position: absolute;
    z-index: 10;
    right: 50%;
    transform: translateX(50%);
    top:20px;
    color: red;
    font-size: 30px;
}