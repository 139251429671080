.checkBox {
  border-radius: 1px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #00d154;
  position: relative;
  box-shadow: inset -1px -1px 1px 0 rgba(0, 0, 0, 0.17);
  cursor: pointer;

  &::after {
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' fill='%23fff' class='bi bi-check' viewBox='0 0 16 16'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'/%3E%3C/svg%3E");
    background-size: 22px;
    background-position: -3px;
    text-align: center;
    color: red;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: #00d154;
    left: 1px;
    top: 1px;
    opacity: 0;
    transition: opacity .18s;
    border-radius: 1px;
  }

  &.active {
    &::after {
      opacity: 1;
    }
  }
}

