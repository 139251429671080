.form {
    /* height: 80px; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.wrapHeader{
    padding: 7px 0px;
    display: flex;
    gap: 5px;
    align-items: flex-end;
    border-bottom: 1px solid rgb(196, 194, 194);
    border-top: 2px solid #000;
}

.header{
    font-weight: 700;
}
.lonlyHeader{
    margin: 0px;
    font-weight: bolder;
}