.checkBox {
  border-radius: 100%;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #c0c0c0;
  position: relative;
  box-shadow: inset -1px -1px 1px 0 rgba(0, 0, 0, 0.17);
  cursor: pointer;
  transition: border-collor .18s;

  &::after {
    content: '';
    background-position: -3px;
    text-align: center;
    color: red;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: #ececec;
    left: 1px;
    top: 1px;
    border-radius: 100%;
    transition: background-color .18s;
  }
  &.homepage{
    margin: 0 80px 20px 0px;
  }
  &.active {
    border-color: #03a7d9;

    &::after {
      background-color: #03a7d9;
    }
  }
}

